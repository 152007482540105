import { Injectable } from '@angular/core';
import { CanActivate,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class LoginAdminGuard implements CanActivate {
  usertype:any;

  constructor(private router: Router) {
    this.usertype = localStorage.getItem('userType');        
  }
  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this.usertype==1){      
      console.log('User Type : Admin User');
      return true;
    }
    this.router.navigateByUrl('/');
    console.log('User Type : Customer');
    return false;    
  }
}
