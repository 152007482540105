import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
//let config = require('config');
import {environment} from '../../../environments/environment';
//import { ChecksessionService } from "./checksession.service";

const api_user: string = environment.url+'users/';
//console.log(api_user);
@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private router:Router, private _http: Http) {  }
// login user
  loginUser(jsonData){
    let headers = new Headers();
    headers.append('Content-Type','application/json');
    headers.append('device_type','3');
    //console.log(jsonData);
    //let _url: string = 'http://localhost:5087/restapi/login';
    let _url: string = api_user+'login';
    return this._http.post(_url, jsonData, {headers:headers})
  .map(res => res.json());
  }

  // get user lists   

    getUserList(){
    let headers = new Headers();
    headers.append('Content-Type','application/json');    
    let _url: string = api_user+'users';
    return this._http.get(_url, {headers:headers})
    .map(res => res.json());
  }

  addUser(jsonData){
    let headers = new Headers();
    headers.append('Content-Type','application/json');
    headers.append('device_type','3'); 
    //console.log(jsonData);
    let _url: string = api_user+'signup';
    return this._http.post(_url, jsonData, {headers:headers})
  .map(res => res.json());
  }

  
  deleteUser(jsonData){
    let headers = new Headers();
    headers.append('Content-Type','application/json');
    //console.log(jsonData);
    let _url: string = api_user+'signup';
    return this._http.post(_url, jsonData, {headers:headers})
  .map(res => res.json());
  }
}